img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-container {
  width: 100vw;
  height: 100vh;
}
body {
  font-family: "Panamera";
  overflow: hidden;
  background-color: black;
}

.name {
  position: absolute;
  color: white;
  top: 28px;
  left: 20px;

  line-height: 0;
  z-index: 1000;
  transition-duration: 0.2s;
}

.name:hover {
  scale: 1.05;
}

.next {
  position: absolute;
  color: white;
  bottom: 30px;
  right: 40px;
  letter-spacing: 0.17px;
  border: 1px solid white;
  padding: 8px;
  transition-duration: 0.1s;
  z-index: 100;
}

.next:hover {
  scale: 1.05;
  border: 2px solid white;
}

.videoSource {
  color: white;
}

.shadows {
  position: fixed;
  inset: 0;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0.27777048319327735) 100%,
    rgba(156, 65, 179, 1) 100%,
    rgba(28, 175, 224, 1) 100%
  );
}

#pulsing-div {
  animation: pulse 6s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  45% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  52% {
    transform: scale(1);
  }
  54% {
    transform: scale(1.1);
  }
  59% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.button-voyager {
  background-color: black;
  font-family: "Optima";
  font-size: 50px;
  color: white;
  text-decoration: none;
  border-radius: 60px;
  border: 1px solid white;
  padding: 6 16 6 16;
  transition-duration: 0.2s;
}

.body-home {
  background-color: black;
}

.voyage-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-voyager:hover {
  scale: 1.05;
  border: 3px solid white;
}

.popup {
  color: red;
  font-size: 200px;
}
.explications {
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 22px;
  z-index: 1002;
  top: 20px;
  right: 30px;
  cursor: pointer;
  transition-duration: 0.2s;
}

.explications:hover {
  scale: 1.2;
  cursor: help;
}

// pop up open//
.is-active {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1001;
  backdrop-filter: blur(10px);

  display: block;
  .pop-up-content {
    position: absolute;
    inset: 50px;
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 300 200 200 200;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    justify-content: space-between;
    align-items: center;
  }
  .close-button {
    height: 30px;
    width: 70px;
    border: 1px solid white;
    display: block;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 16px;
    padding-bottom: 40px;
  }
}

.close-button:hover {
  scale: 1.06;
}

.is-hidden {
  // display: none;
  opacity: 0;
}

a {
  text-decoration: none;
}

.intro {
  z-index: 2;
  position: fixed;
  inset: 0;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-button:hover {
  transform: scale(1.1);
  cursor: pointer;
  scale: 1.05;
  border: 1px solid white;
  //à améliorer
}

.intro-button {
  border: 1px solid white;
  padding: 10px;
  transition-duration: 0.2s;
}
.video-container {
  width: 100vw;
  height: 100vh;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.intro-button {
  background-color: transparent;
  color: white;
}

.launch-button {
  background-color: transparent;
}
