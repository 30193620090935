* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img, video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-container {
  width: 100vw;
  height: 100vh;
}

body {
  background-color: #000;
  font-family: Panamera;
  overflow: hidden;
}

.name {
  color: #fff;
  z-index: 1000;
  line-height: 0;
  transition-duration: .2s;
  position: absolute;
  top: 28px;
  left: 20px;
}

.name:hover {
  scale: 1.05;
}

.next {
  color: #fff;
  letter-spacing: .17px;
  z-index: 100;
  border: 1px solid #fff;
  padding: 8px;
  transition-duration: .1s;
  position: absolute;
  bottom: 30px;
  right: 40px;
}

.next:hover {
  border: 2px solid #fff;
  scale: 1.05;
}

.videoSource {
  color: #fff;
}

.shadows {
  background: radial-gradient(circle, #0000 80%, #00000047 100%, #9c41b3 100%, #1cafe0 100%);
  position: fixed;
  inset: 0;
}

#pulsing-div {
  animation: pulse 6s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  45% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  52% {
    transform: scale(1);
  }

  54% {
    transform: scale(1.1);
  }

  59% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.button-voyager {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 60px;
  padding: 6px 16px;
  font-family: Optima;
  font-size: 50px;
  text-decoration: none;
  transition-duration: .2s;
}

.body-home {
  background-color: #000;
}

.voyage-container {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-voyager:hover {
  border: 3px solid #fff;
  scale: 1.05;
}

.popup {
  color: red;
  font-size: 200px;
}

.explications {
  color: #fff;
  z-index: 1002;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  transition-duration: .2s;
  position: absolute;
  top: 20px;
  right: 30px;
}

.explications:hover {
  cursor: help;
  scale: 1.2;
}

.is-active {
  z-index: 1001;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #0009;
  display: block;
  position: fixed;
  inset: 0;
}

.is-active .pop-up-content {
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 300px 200px 200px;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  position: absolute;
  inset: 50px;
}

.is-active .close-button {
  height: 30px;
  width: 70px;
  cursor: pointer;
  border: 1px solid #fff;
  padding-bottom: 40px;
  font-size: 16px;
  transition-duration: .2s;
  display: block;
}

.close-button:hover {
  scale: 1.06;
}

.is-hidden {
  opacity: 0;
}

a {
  text-decoration: none;
}

.intro {
  z-index: 2;
  color: #fff;
  background: #000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.intro-button:hover {
  cursor: pointer;
  border: 1px solid #fff;
  transform: scale(1.1)scale3d(1.05, 1.05, 1);
}

.intro-button {
  border: 1px solid #fff;
  padding: 10px;
  transition-duration: .2s;
}

.video-container {
  width: 100vw;
  height: 100vh;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.intro-button {
  color: #fff;
  background-color: #0000;
}

.launch-button {
  background-color: #0000;
}

@font-face {
  font-family: Panamera;
  src: url("../fonts/Panamera-Regular.woff") format("woff2");
  font-weight: 100 900;
}

/*# sourceMappingURL=index.css.map */
